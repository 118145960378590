<template>
  <div class="app-container">
  <!-- 顶部按钮 -->
    <div class="top_btns clearfix">
      <div class="right_btns fr">
        <qzf-video vid="4467be5374e4ef7c2822482cae694041_4"></qzf-video>

        <qzf-button button_code="xc_gzb_tj" class="" type="success" @success="handleCreate" size="small"   >
          <el-icon><CirclePlus /></el-icon><span  > 添加</span>
        </qzf-button>
        <qzf-button button_code="xc_gzb_fz" type="primary" size="small" @success="copySalary()"   >
          <el-icon><DocumentCopy /></el-icon><span  > 复制往期薪酬</span>
        </qzf-button>
        <settings name="凭证科目配置" :type="['code_salary_wages','code_salary_wages_admin','code_salary_sc','code_salary_zz','code_salary_xs','code_salary_lw','code_salary_qt','code_salary_dksb','code_salary_dkgjj','code_salary_djgs','code_salary_dkqt','code_salary_lwbc_in','code_salary_lwbc_out','code_salary_only_in','code_salary_only_out']"></settings>
      </div>
    </div>
    <!-- top -->
    <!-- list -->
    <el-table stripe v-loading="listLoading" :data="list" border fit highlight-current-row style="width: 100%; margin-top:10px;" :header-cell-style="{background: '#eee',color:'#333','font-size': '13px'}">
      <template #empty>
        <el-empty :image-size="150" description="没有数据"></el-empty>
      </template>
      <el-table-column label="序号" prop="id" align="center" width="60" type="index"></el-table-column>
      <el-table-column label="薪酬类型" min-width="200">
        <template #default="{row}">
          <span>{{row.wageType}}</span>
        </template>
      </el-table-column>
      <el-table-column label="薪酬所属期" min-width="120">
        <template #default="{row}">
          <span>{{row.period}}</span>
        </template>
      </el-table-column>
      <el-table-column label="人数" min-width="60">
        <template #default="{row}">
          <span>{{row.count}}</span>
        </template>
      </el-table-column>

      <el-table-column label="工资合计" min-width="120">
        <template #default="{row}">
           <span v-if="row.wageType != '个人生产经营所得(查账征收)'">{{(row.amount1 + row.amount2).toFixed(2)}}</span>
           <span v-else>--</span>
        </template>
      </el-table-column>
      <el-table-column label="凭证号" min-width="140">
        <template #default="{row}">
          <span>{{row.voucher_no}}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center" min-width="160" class-name="small-padding fixed-width top_right_btns">
        <template #default="{row}">

          <!-- <el-tooltip content="凭证" placement="top">
            <i class="iconfont icon-pingzheng" @click="editVoucher(row)"></i>
          </el-tooltip> -->
          <el-tooltip content="编辑" placement="top">
            <i class="iconfont icon-bianji" @click="editRow(row)"></i>
          </el-tooltip>
          <el-tooltip content="删除" placement="top" v-if="$buttonStatus('xc_gzb_tj')">
            <i class="iconfont icon-shanchu" @click="delRow(row)"></i>
          </el-tooltip>
          <el-tooltip content="设置" placement="top" v-if="$buttonStatus('xc_gzb_tj')">
            <i class="iconfont icon-guanlishezhi" @click="handleUpdate(row)"></i>
          </el-tooltip>
        </template>
      </el-table-column>
    </el-table>
    <!-- list end -->
    <addSalary ref="addSalary" @success="getList"></addSalary>
  </div>
</template>

<script>
import { salarySheetList,delEaSalarySheet,oeClickReplicationOne } from '@/api/salary.js'
import addSalary from './addSalary.vue'
export default {
  name: 'salary',
  components:{ addSalary },
  data() {
    return {
      temp:{
        comType: "个体工商户",
        zsfs: "据实预缴",
      },
      list:[]
    }
  },
  created() {
    this.contentStyleObj= this.$getHeight(280)
    this.getList()
  },
  methods: {
    //新增
    handleCreate() {
      this.dialogStatus = 'create'
      // this.temp.dateEnd = this.$store.getters.currentPeriod
      this.$refs.addSalary.openSalary()
    },
    //删除
    delRow(e) {
      if(this.$checkSettleStatus() != ""){
        this.$message.error(this.$checkSettleStatus())
        return 
      }
      this.$confirm("确认删除该薪酬表", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        delEaSalarySheet({ id: e.id }).then(res => {
          if(res.data.msg == "success"){
            this.getList();
            this.$message({
              type: "success",
              message: "删除成功"
            });
          }
        });
      })
    },
    //
    handleUpdate(row) {
      if(this.$checkSettleStatus() != ""){
        this.$message.error(this.$checkSettleStatus())
        return 
      }
      this.$refs.addSalary.openSalary(row)
    },
    //列表展示
    getList() {
      salarySheetList({}).then(response => {
        this.list = response.data.data.list
      })
    },

    editRow(e) {
    if(e.wageType == '正常工资薪资'){
      this.$store.dispatch('commons/setParam', {salaryNormalId: e.id})
      this.$store.dispatch('tagsView/delCachedViewByName', "salaryNormal")
      this.$router.push({
        path: "/input/remuneration/salaryNormal",
        name: "salaryNormal"
      });
    }
    if(e.wageType == '个人生产经营所得(查账征收)'){
      this.$store.dispatch('commons/setParam', {salaryPersonId: e.id})
      this.$store.dispatch('tagsView/delCachedViewByName', "salaryPerson")
      this.$router.push({
        path: "/input/remuneration/salaryPerson",
        name: "salaryPerson",
      });
    }
    if(e.wageType == '劳务报酬所得'){
      this.$store.dispatch('commons/setParam', {salaryServicesId: e.id})
      this.$store.dispatch('tagsView/delCachedViewByName', "salaryServices")
      this.$router.push({
        path: "/input/remuneration/salaryServices",
        name: "salaryServices",
      });
    }
    if(e.wageType == '全年一次性奖金'){
      this.$store.dispatch('commons/setParam', {salaryBonusId: e.id})
      this.$store.dispatch('tagsView/delCachedViewByName', "salaryBonus")
      this.$router.push({
        path: "/input/remuneration/salaryBonus",
        name: "salaryBonus",
      });
    }
  },
  //复制往期薪酬
  copySalary() {
      this.$confirm("确认复制上月薪酬表？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        oeClickReplicationOne({}).then(e => {
          console.log(e)
          if(e.data.msg == '已存在数据不能再次生成') {
            this.$message({
              message: '复制失败！该薪资表已存在',
              type: 'warning'
            })
          } else if(e.data.msg == "success") {
            this.$qzfMessage("复制成功", 3)
            this.getList()
          }
        });
      })
    },
  }
}
</script>

<style scoped lang="scss">
.icon-bianji,.icon-shanchu,.icon-guanlishezhi,.icon-pingzheng {
    margin: 0 5px;
    cursor: pointer;
    color: $color-main;
    font-size: 18px;
    line-height: 24px;
}
.pagination{
  margin-top: 15px;
}
</style>