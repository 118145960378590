<template>
  <div class="app-container">
    <div class="right_table">
      <div class="top_btns clearfix">
          <div class="left_search fl">
            <p style="display:inline-block;font-size:14px;color:red;line-height:36px;">温馨提示：删除人员请谨慎！已有收入明细数据的人员如若删除将会影响申报数据！</p>
          </div>
          <div class="right_btns fr">
            <qzf-video vid="4467be5374915ce8cb3b208deaaabf2e_4"></qzf-video>

            <buttonStatus name="专项扣除" @success="zxfjcj()" ref="btnZxfj"></buttonStatus>
            <el-dropdown split-button type="success" @click="addPersonnel" size="small" style="margin-right:10px" :hide-on-click="false">
              <i class="iconfont icon-jiahao"></i> 添加
              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item>
                    <span size="small" @click="openCom">员工导入</span>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
            <qzf-button jz="false" button_code="xc_yg_sc" type="danger" size="small" @success="handleClickDel"   >
              <el-icon><Delete /></el-icon> <span  >批量删除</span>
            </qzf-button>
            <settings :type="['code_xzgs', 'code_gz_merge']"></settings>
            <el-dropdown @command="batchAll" style="margin-left:6px" size="small">
              <qzf-button jz="false" button_code="xc_yg_gd" type="primary" size="small">
                更多<el-icon class="el-icon--right"><arrow-down /></el-icon>
              </qzf-button>
              <template #dropdown>
                <el-dropdown-menu style="text-align:center">
                  <el-dropdown-item command="f">
                    <span @click="daochu">导出员工</span>
                  </el-dropdown-item>
                  <el-dropdown-item command="b">
                    <span>批量修改为正常</span>
                  </el-dropdown-item>
                  <el-dropdown-item command="a">
                    <span>批量修改为非正常</span>
                  </el-dropdown-item>
                  <el-dropdown-item command="g">
                    <span @click="addallzxkc">导入专项附加扣除</span>
                  </el-dropdown-item>
                  <el-dropdown-item command="c">
                    <span>批量修改为6万扣除</span>
                  </el-dropdown-item>
                  <el-dropdown-item command="d">
                    <span>批量修改为正常扣除</span>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </template>
              
            </el-dropdown>
          </div>
      </div>
      <!-- 顶部金额 -->
      <div class=" clearfix">
        <el-select v-model="listQuery.status" filterable @change="getList()" style="width:10%;" size="small">
          <el-option label="全部" :value ="0"></el-option>
          <el-option label="正常" :value ="1"></el-option>
          <el-option label="非正常" :value="2"></el-option>
        </el-select>
        <el-input placeholder="姓名/证件号码" style="width: 200px;" class="filter-item" v-model="listQuery.name" size="small" @keyup.enter="getList"/>
        <qzf-button jz="false" button_code="yg_ss" class="filter-item"    type="primary" size="small" @success="getList()">
          <el-icon><Search /></el-icon> <span  >搜索</span>
        </qzf-button>
      </div>
      <!-- 顶部金额 end -->
      <el-table stripe :height="contentStyleObj" @selection-change="handleSelectionChange" v-loading="listLoading" :data="list" border fit highlight-current-row style="width: 100%; margin-top: 10px;" :header-cell-style="{background: '#eee',color:'#333','font-size': '13px'}">
        <template #empty>
          <el-empty :image-size="150" description="没有数据"></el-empty>
        </template>
        <el-table-column type="selection" align="center" width="40"/>
        <el-table-column align="center" type="index" width="60" label="序号"> </el-table-column>
        <el-table-column label="姓名"  min-width="80">
            <template #default="{row}">
                <span>{{row.name}}</span>
            </template>
        </el-table-column>
        <el-table-column label="证件类型" min-width="100">
          <template #default="{row}">
                <span>{{row.iDType}}</span>
          </template>
        </el-table-column>
        <el-table-column label="证件号码" min-width="180">
          <template #default="{row}">
                <span>{{row.iDNo}}</span>
          </template>
        </el-table-column>
        <el-table-column label="性别" min-width="50">
          <template #default="{row}">
            <span v-if="row.sex == 1">男</span>
                <span v-else>女</span>
          </template>
        </el-table-column>
        <el-table-column label="出生日期" min-width="100">
          <template #default="{row}">
                <span>{{row.birthday}}</span>
          </template>
        </el-table-column>
        <el-table-column label="是否雇员" min-width="80">
          <template #default="{row}">
            <span v-if="row.isEmployee == 1">是</span>
            <span v-else>否</span>
          </template>
        </el-table-column>
        <el-table-column label="任职受雇日期" min-width="120">
          <template #default="{row}">
                <span>{{row.joinDate}}</span>
          </template>
        </el-table-column>
        <el-table-column label="离职日期" min-width="100">
          <template #default="{row}">
                <span>{{row.departureDate}}</span>
          </template>
        </el-table-column>
        <el-table-column label="是否股东" min-width="80">
          <template #default="{row}">
            <span v-if="row.stockholder == 1">是</span>
            <span v-else>否</span>
          </template>
        </el-table-column>
        <el-table-column label="国籍" min-width="80">
          <template #default="{row}">
                <span>{{row.country}}</span>
          </template>
        </el-table-column>
        <el-table-column label="部门" min-width="100">
          <template #default="{row}">
                <span>{{row.department}}</span>
          </template>
        </el-table-column>

        <el-table-column label="按6万扣除" min-width="100">
          <template #default="{row}">
                <span v-if="row.jcStatus == 1">是</span>
                <span v-else>否</span>
          </template>
        </el-table-column>
        <el-table-column label="人员状态" min-width="90">
          <template #default="{row}">
          <qzf-button jz="false" button_code="yg_zc" type="success" v-if="row.status == '1'" size="small" class="tablebutton" @success="editStatus(row,1)" plain>正常</qzf-button>
          <qzf-button jz="false" button_code="yg_zc" type="danger" v-else size="small" class="tablebutton" @success="editStatus(row,2)" plain>非正常</qzf-button>
          </template>   
        </el-table-column>
        <el-table-column label="操作" align="center" min-width="120" class-name="sizeBor small-padding fixed-width top_right_btns">
          <template #default="{row}">
            <el-tooltip content="编辑" placement="top">
              <i class="iconfont icon-bianji iconStyle" @click="editDomesticPersonnel(row)"></i>
            </el-tooltip>
            <el-tooltip content="删除" placement="top" v-if="$buttonStatus('yg_zc')">
              <i class="iconfont icon-shanchu iconStyle" @click="handleModifyStatus(row,'deleted')"></i>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
    </div>
    
    <div class="bottom_style">
      <div class="bottom_button">
      </div>
      <div class="pagination">
        <qzf-pagination v-show="total>0" :total="total" v-model:page="listQuery.page" v-model:limit="listQuery.limit" @pagination="getList" />
      </div>
    </div>
 

    <el-dialog :close-on-click-modal="false" title="离职日期" v-model="dialogFormVisible2"  class="button_bg">
      <el-form ref="dataForm" :model="temp">
        <el-form-item label="离职日期" label-width="140">
          <el-date-picker size="small"  type="date" value-format="YYYY-MM-DD" v-model="temp.departureDate" placeholder="选择日期"></el-date-picker>
        </el-form-item>
      </el-form>

      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogFormVisible2 = false" size="mini">取消</el-button>
          <el-button type="primary" @click="saveTime()" size="mini">确定</el-button>
        </span>
      </template>

    </el-dialog>


    <qzf-import-back :hideButton="true" @success="getList()" ref="importBck" from="员工"></qzf-import-back>
    <qzf-import-back :hideButton="true" @success="getList()" ref="importBck1" from="专项扣除"></qzf-import-back>

    <addEmployee ref="addEmployee" :type1="this.type" @success="getList"></addEmployee>
    <changeStatus ref="changeStatus" :ids="this.ids"></changeStatus>
  </div>
</template>

<script>
import { salaryList,delEaEmployee,saveEmployees } from "@/api/salary.js"
import { exportSalary } from "@/api/export"
import addEmployee from './addEmployee.vue'
import changeStatus from './changeStatus.vue'
export default {
  name: 'employee',
  components: {
      addEmployee,
      changeStatus
  } ,
  data() {
    return{
      listQuery: {
        "status": 0,
        "page": 1,
        "limit":20,
        "name": '',
        "idNo": ''
      },
      list:[],
      ids:[],
      listLoading: false,
      total: 0,
      type: '',
      temp:{},
      dialogFormVisible2:false
    }
  },
  created() {
    this.contentStyleObj= this.$getHeight(280)
    this.getList()
  },
  methods: {
    handleSelectionChange(e){
      this.ids = e
      console.log(this.ids,'this.ids')
    },
    // 更多
    batchAll(command){
      if(command == 'a'){
        this.batchSave()
      }else if(command == 'b'){
        this.batchSaveNormal()
      }else if(command == 'c'){
        this.batchSaveJcStatus(1)
      }else if(command == 'd'){
        this.batchSaveJcStatus(0)
      }
    },
    batchSaveNormal(){
      if(this.$checkSettleStatus() != ""){
        this.$message.error(this.$checkSettleStatus())
        return 
      }
      if(this.ids.length == 0){
        this.$message.error('至少选择一个')
        return
      }
      this.ids.map(v=>{
        v.departureDate = ""
        v.status = 1
      })
      saveEmployees(this.ids).then(res=>{
        if(res.data.msg == 'success'){
          this.$message.success('修改成功')
        }
      })
    },
    // 是否按6万扣除
    batchSaveJcStatus(status){
      if(this.$checkSettleStatus() != ""){
        this.$message.error(this.$checkSettleStatus())
        return 
      }
      if(this.ids.length == 0){
        this.$message.error('至少选择一个')
        return
      }
      this.ids.map(v=>{
        v.jcStatus = status
      })
      saveEmployees(this.ids).then(res=>{
        if(res.data.msg == 'success'){
          this.$message.success('修改成功')
        }
        this.getList()
      })
    },
    //批量删除
    handleClickDel(){
      if(this.ids.length == 0){
          this.$qzfMessage("请至少选择一个",1)
        }else{
          this.$confirm("确定删除选项吗？", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning"
          }).then(() => {
            let idss=[]
            this.ids.map(item=>{
              idss.push({id:item.id})
            })
            delEaEmployee(idss).then(res=>{
              if(res.data.msg == "success"){
                this.$qzfMessage("删除成功")
                this.getList()
              }else{
                this.$qzfMessage("删除失败",1)
              }
            })
          }); 
        }
    },
    //编辑
    editDomesticPersonnel(row) {
      if(this.$checkSettleStatus() != ""){
        this.$message.error(this.$checkSettleStatus())
        return 
      }
      this.type = '编辑'
      let data = this.$qzfCopy(row)
      this.$refs.addEmployee.openDialog(data)
    },
    //新增
    addPersonnel() {
      if(this.$checkSettleStatus() != ""){
        this.$message.error(this.$checkSettleStatus())
        return 
      }
      this.type = '新增'
      console.log(this.type,'this.type')
      this.$refs.addEmployee.openDialog()
    },
    batchSave() {
      if(this.$checkSettleStatus() != ""){
        this.$message.error(this.$checkSettleStatus())
        return 
      }
      if(this.ids.length == 0){
        this.$message.error('至少选择一个')
        return
      }
      this.$refs.changeStatus.openDialog()
    },
    //里边删除
    handleModifyStatus(row) {
      if(this.$checkSettleStatus() != ""){
        this.$message.error(this.$checkSettleStatus())
        return 
      }
      this.$confirm('该人员已存在薪酬数据，修改人员信息将影响薪酬数据，请确定是否继续此操作', '信息', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$confirm('确定要删除吗', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
        delEaEmployee([{id: row.id}]).then(res=>{
          if(res.data.msg != "success") {
            this.$confirm(res.data.msg, '信息', {
              confirmButtonText: '确定',
              type: 'warning'
            })
            return
          }
          this.$message({
            type: 'success',
            message: '删除成功'
          });
          this.getList()
        })
      })
          
      })
    },
    //展示列表
    getList(){
      this.listLoading = true
      salaryList(this.listQuery).then(res=>{
        this.listLoading = false
        if(res.data.data.list){
          this.list = res.data.data.list
          this.total = res.data.data.total
        }else{
          this.list = []
          this.total = 0
        }
      })
    },
    openCom(){
      this.$refs.importBck.handImport()
    },
    addallzxkc(){
      this.$refs.importBck1.handImport()
    },
    daochu(){
      let param = {
        period:this.$store.getters['user/comInfo'].period,
        comId:this.$store.getters['user/comInfo'].comId*1,
        type:'people'
      }
      exportSalary(param).then(res=>{
        if(res.data.msg == "success"){
          window.open(res.data.data.url)
        }
      })
    },
    editStatus(e,status) {
      if(this.$checkSettleStatus() != ""){
        this.$message.error(this.$checkSettleStatus())
        return 
      }
      this.temp = Object.assign({}, e) // copy obj
      if(status == 2){
        this.$confirm('确定恢复该数据？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.temp.departureDate = ''
        this.temp.status = 1
        this.temp.isEmployee = 1
          saveEmployees([this.temp]).then(res=>{
            this.getList()
          })
          this.$qzfMessage("修改成功")
        })
        return false
      }
      this.$confirm('确定此员工离职', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.dialogFormVisible2 = true
      })
    },
    saveTime() {
      if(this.temp.departureDate == '') {
        this.$message.error('请选择离职日期!')
        return
      }
      this.temp.status = 2
      this.temp.isEmployee = 2

      saveEmployees([this.temp]).then(res=>{
        if(res.data.msg == 'success'){
          this.$qzfMessage("修改成功")
          this.dialogFormVisible2 = false
          this.getList()
        }
      })
    },
  }
}
</script>

<style lang="scss" scoped>
 
.iconStyle{
  margin: 0 5px;
  cursor: pointer;
  color: $color-main;
  font-size: 18px;
}
.el-table {
  margin-top: 20px;
}
.el-table--mini .el-table__cell {
    padding: 0;
}
.pagination{
  margin-top: 15px;
}
</style>