<template>
  <el-dialog :close-on-click-modal="false" destroy-on-close title="离职日期" v-model="dialogFormVisiblebatch"  class="button_bg">
    <el-form ref="dataForm">
      <el-form-item label="离职日期" label-width="140">
        <el-date-picker style="width:60%;margin-left: 6px;" type="datetime" placeholder="选择日期时间" value-format="YYYY-MM-DD" v-model="batchTime" size="small"></el-date-picker>
      </el-form-item>

      <el-form-item label="是否雇员" label-width="140">
        <el-select size="small" v-model="isEmployee" filterable style="width:58%;">
          <el-option v-for="itemb in options" :key="itemb.value" :label="itemb.label" :value="itemb.value">
          </el-option>
        </el-select>
      </el-form-item>

    </el-form>
    <template #footer>
      <div class="dialog-footer">
        <el-button @click="dialogFormVisiblebatch = false" size="small">取消</el-button>
        <el-button type="primary" @click="saveTimebatch()" size="small">确定</el-button>
      </div>
    </template>
      
    </el-dialog>
</template>

<script>
import { saveEmployees } from "@/api/salary.js"
export default {
  name:'changeStatus',
  props:{
    ids:{
      // type: String,
    }
  },
  data() {
    return {
      batchTime: "",
      isEmployee: 2,
      dialogFormVisiblebatch: false,
      options: [
        {
          value: 1,
          label: "是"
        },
        {
          value: 2,
          label: "否"
        }
      ],
    }
  },
  methods: {
    openDialog() {
      this.dialogFormVisiblebatch = true
    },
    saveTimebatch(){
      if(this.batchTime == '') {
        this.$message.error('请选择离职日期!')
        return
      }
      this.ids.map(v=>{
        v.isEmployee = Number(this.isEmployee)
        v.departureDate = this.batchTime
        v.status = 2
      })
      this.dialogFormVisiblebatch = false
      saveEmployees(this.ids).then(res=>{
        if(res.data.msg == "success") {
          this.$message.success('修改成功')
        }
      })
    },
  }
}
</script>

<style>

</style>