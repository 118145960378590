<template>
<el-dialog :close-on-click-modal="false"
    v-model="dialogFormVisible"
    :title="this.type1"
    width="80%"
    destroy-on-close
  >
  <el-form ref="dataForm" :rules="rules" :model="info" size="small" style="max-height:620px;overflow:auto" label-position="right" :inline="true" label-width="140px" class="demo-form-inline">
    <div class="common_dialog_box clearfix">
      <div class="common_title">基本信息</div>
      <div class="item_one clearfix">
        <el-form-item label="姓名:" prop="name" class="item">
          <el-input v-model="info.name" size="small" />
        </el-form-item>
        <el-form-item label="证件类型:" prop="iDType" class="item">
          <el-select size="small" v-model="info.iDType" filterable placeholder="证照类型">
            <el-option v-for="itemb in iDTypes" :key="itemb.id" :label="itemb.label" :value="itemb.value">
            </el-option>
        </el-select>
        </el-form-item>
        <el-form-item label="证件号码:" prop="iDNo" class="item">
          <el-input size="small" @change="idnoChange" v-model="info.iDNo" />
        </el-form-item>
        <el-form-item label="国籍(地区):" prop="country" class="item">
          <el-select size="small" v-model="info.country" filterable placeholder="中国">
            <el-option label="中国" value="中国"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="性别:" prop="sex" class="item">
          <el-select size="small" v-model="info.sex" filterable placeholder="">
            <el-option v-for="item in sex" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="手机号码:" prop="tel" class="item">
          <el-input size="small" v-model="info.tel" />
        </el-form-item>
        <el-form-item label="出生日期:" prop="birthday" class="item">
          <el-date-picker size="small" v-model="info.birthday" value-format="YYYY-MM-DD" placeholder="选择日期"></el-date-picker>
        </el-form-item>
        <el-form-item label="人员状态:" prop="status" class="item">
          <el-select size="small" v-model="info.status" filterable placeholder="">
            <el-option label="正常" :value=1></el-option>
            <el-option label="非正常" :value=2></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="是否残疾:" class="item">
          <el-select size="small" v-model="info.disability" filterable placeholder="">
            <el-option v-for="itemb in options" :key="itemb.value" :label="itemb.label" :value="itemb.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="残疾证号:" class="item">
          <el-input size="small" v-if="this.info.disability === 2 || this.info.disability === 0" v-model="info.disabilityNo" disabled/>
          <el-input size="small" v-else v-model="info.disabilityNo" />          
        </el-form-item>
        <el-form-item label="是否烈属:" class="item">
          <el-select size="small" v-model="info.martyred" filterable placeholder="">
            <el-option v-for="itemb in options" :key="itemb.value" :label="itemb.label" :value="itemb.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="烈属证号:" class="item">
          <el-input size="small" v-if="this.info.martyred === 2 || this.info.martyred === 0" v-model="info.martyredNo" disabled/>
          <el-input size="small" v-else v-model="info.martyredNo" />       
        </el-form-item>
        <el-form-item label="是否孤老:" class="item">
          <el-select size="small" v-model="info.elderly" filterable placeholder="">
            <el-option v-for="itemb in options" :key="itemb.value" :label="itemb.label" :value="itemb.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="备注:" class="item">
          <el-input size="small" v-model="info.remark"  />      
        </el-form-item>
      </div>
    </div>
    <div class="common_dialog_box clearfix">
      <div class="common_title">任职受雇信息</div>
      <div class="item_one clearfix">
        <el-form-item label="是否雇员:" prop="isEmployee" class="item">
          <el-select size="small" v-model="info.isEmployee" filterable>
            <el-option v-for="itemb in options" :key="itemb.value" :label="itemb.label" :value="itemb.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="是否股东、投资者:" class="item">
          <el-select size="small" v-model="info.stockholder" filterable>
            <el-option v-for="itemb in options" :key="itemb.value" :label="itemb.label" :value="itemb.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="公司股本总额:" class="item">
          <el-input size="small" v-if="this.info.stockholder === 2 || this.info.stockholder === 0"  type="number" v-model.number="info.totalStock" disabled/>
          <el-input size="small" v-else  type="number" v-model.number="info.totalStock" />
        </el-form-item>
        <el-form-item label="个人股本(投资)额:" class="item">
          <el-input size="small" v-if="this.info.stockholder === 2 || this.info.stockholder === 0"  type="number" v-model.number="info.totalStock" disabled/>
          <el-input size="small" v-else  type="number" v-model.number="info.totalStock" />
          <!-- <span>元</span> -->
        </el-form-item>
        <el-form-item label="分配比例（%）:" class="item">
          <el-input size="small" v-if="this.info.stockholder === 2 || this.info.stockholder === 0"  type="number" v-model.number="info.stockPercent" disabled/>
          <el-input size="small" v-else  type="number" v-model.number="info.stockPercent" />
          <!-- <span>%</span> -->
        </el-form-item>
        <el-form-item label="自然人登录密码:" class="item">
          <el-input size="small" v-if="this.info.stockholder === 2 || this.info.stockholder === 0" v-model="info.personLoginPwd" disabled/>
          <el-input size="small" v-else />
        </el-form-item>
        <el-form-item label="受雇日期:" prop="joinDate" class="item">
          <el-date-picker size="small" v-model="info.joinDate" type="date" placeholder="选择日期" value-format="YYYY-MM-DD" :disabled-date="disabledDate"></el-date-picker>
        </el-form-item>
        <el-form-item label="离职日期:" class="item" prop="departureDate" v-if="this.info.status === 2">
          <el-date-picker size="small" v-if="this.info.status === 2" v-model="info.departureDate" type="date" value-format="YYYY-MM-DD" placeholder="选择日期"></el-date-picker>
          <!-- <el-date-picker size="small" v-else style="width:48%;" v-model="value3" type="date" placeholder="选择日期" disabled></el-date-picker>       -->
        </el-form-item>
        <el-form-item label="子女教育:" class="item">
          <el-input size="small"  type="number" v-model.number="info.childrenEducation" />
          <el-date-picker size="small" v-model="info.time1" type="date" placeholder="初次使用日期" value-format="YYYY-MM-DD"></el-date-picker>
          <el-date-picker size="small" v-model="info.timeEnd1" type="date" placeholder="终止日期" value-format="YYYY-MM-DD"></el-date-picker>          
        </el-form-item>
        <el-form-item label="继续教育:" class="item">
          <el-input size="small"  type="number" v-model.number="info.continuingEducation" />
          <el-date-picker size="small" v-model="info.time2" type="date" placeholder="初次使用日期" value-format="YYYY-MM-DD"></el-date-picker>
          <el-date-picker size="small" v-model="info.timeEnd2" type="date" placeholder="终止日期" value-format="YYYY-MM-DD"></el-date-picker>      
        </el-form-item>
        <el-form-item label="大病医疗:" class="item">
          <el-input size="small"  type="number" v-model.number="info.seriousIllness" />
        <el-date-picker size="small" v-model="info.time3" type="date" placeholder="初次使用日期" value-format="YYYY-MM-DD"></el-date-picker>
        <el-date-picker size="small" v-model="info.timeEnd3" type="date" placeholder="终止日期" value-format="YYYY-MM-DD"></el-date-picker>    
        </el-form-item>
        <el-form-item label="住房贷款利息:" class="item">
          <el-input size="small"  type="number" v-model.number="info.housingLoan" />
          <el-date-picker size="small" v-model="info.time4" type="date" placeholder="初次使用日期" value-format="YYYY-MM-DD"></el-date-picker>
          <el-date-picker size="small" v-model="info.timeEnd4" type="date" placeholder="终止日期" value-format="YYYY-MM-DD"></el-date-picker>
        </el-form-item>
        <el-form-item label="住房租金:" class="item">
          <el-input size="small"  type="number" v-model.number="info.housingRent" />
          <el-date-picker size="small" v-model="info.time5" type="date" placeholder="初次使用日期" value-format="YYYY-MM-DD"></el-date-picker>
          <el-date-picker size="small" v-model="info.timeEnd5" type="date" placeholder="终止日期" value-format="YYYY-MM-DD"></el-date-picker>
        </el-form-item>
        <el-form-item label="赡养老人:" class="item">
          <el-input size="small"  type="number" v-model.number="info.support" />
          <el-date-picker size="small" v-model="info.time6" type="date" placeholder="初次使用日期" value-format="YYYY-MM-DD"></el-date-picker>
          <el-date-picker size="small" v-model="info.timeEnd6" type="date" placeholder="终止日期" value-format="YYYY-MM-DD"></el-date-picker>
        </el-form-item>
        <el-form-item label="婴幼儿照护费用:" class="item">
          <el-input size="small"  type="number" v-model.number="info.babyReduction" />
          <el-date-picker size="small" v-model="info.time7" type="date" placeholder="初次使用日期" value-format="YYYY-MM-DD"></el-date-picker>
          <el-date-picker size="small" v-model="info.timeEnd7" type="date" placeholder="终止日期" value-format="YYYY-MM-DD"></el-date-picker>
        </el-form-item>
      </div>
    </div>
  </el-form>
  <template #footer>
    <span class="dialog-footer">
      <el-button @click="dialogFormVisible = false" size="small">取消</el-button>
      <el-button type="primary" @click="saveadd" size="small">确定</el-button>
    </span>
  </template>
</el-dialog>
</template>

<script>
import { saveEmployees } from "@/api/salary.js"
export default {
  name: 'addEmployee',
  props: {
    type1: {
      type: String,
    }
  },
  mounted() {
    console.log(this.$store.getters['user/comInfo'].period,'this.type1')
  },
  data() {
    return {
      listQuery: {
        // "status": 0,
        "page": 1,
        "limit":20,
        // "name": '',
        // "idNo": ''
      },
      disabledDate: (time) => {
        return time.getTime() > new Date(this.$getLastDay(this.$store.getters['user/comInfo'].period)).getTime()
      },
      dialogFormVisible:false,
      temp:{},
      info:{
        iDType: "居民身份证",
        country: "中国",
        iDNo: '',
        birthday: '',
        sex: '',
        disability: 2,
        isEmployee: 1,
        stockholder: 2,
        martyred: 2,
        elderly: 2,
        totalStock:0,
        personalStock:0,
        stockPercent:0,
        joinDate:'',
        departureDate:''
      },
      sex: [
        {
          value: 1,
          label: "男"
        },
        {
          value: 2,
          label: "女"
        }
      ],
      iDTypes: [
        {
          value: "居民身份证",
          label: "居民身份证"
        },
      ],
      options: [
        {
          value: 1,
          label: "是"
        },
        {
          value: 2,
          label: "否"
        }
      ],
      value3: "",
      value8: "",
      rules: {
        name: [{ required: true, message: '姓名不能为空', trigger: 'change' }],
        iDType: [{ required: true, message: '请选择证件类型', trigger: 'change' }],
        iDNo: [{ required: true, message: '证件号码不能为空', trigger: 'change' }],
        country: [{ required: true, message: '请选择国籍(地区)', trigger: 'change' }],
        sex: [{ required: true, message: '请选择性别', trigger: 'change' }],
        tel: [{ required: true, message: '手机号码不能为空', trigger: 'change' }],
        birthday: [{ required: true, message: '出生日期不能为空', trigger: 'change' }],
        status: [{ required: true, message: '请选择人员状态', trigger: 'change' }],
        isEmployee: [{ required: true, message: '请选择是否雇员', trigger: 'change' }],
        joinDate: [{ required: true, message: '请输入受雇日期', trigger: 'change' }],
        departureDate: [{ required: true, message: '请输入离职日期', trigger: 'change' }],
      },
    }
  },
  methods: {
    idnoChange() {
      if(this.info.iDNo.length == 18){
        let nian = this.info.iDNo.substring(6,10)
        let yue = this.info.iDNo.substring(10,12)
        let ri = this.info.iDNo.substring(12,14)
        this.info.birthday = nian + '-' + yue + '-' + ri
        let sex = this.info.iDNo.substring(16,17)
        if(sex%2 == 1) {
          this.info.sex = 1
        }else{
          this.info.sex = 2
        }
      }
    },
    saveadd() {
      this.info.comId = this.info.comId * 1;
      this.info.totalStock = Number(this.info.totalStock)
      this.info.personalStock = Number(this.info.personalStock)
      this.info.stockPercent = Number(this.info.stockPercent)

      this.info.childrenEducation = Number(this.info.childrenEducation)
      this.info.continuingEducation = Number(this.info.continuingEducation)
      this.info.seriousIllness = Number(this.info.seriousIllness)
      this.info.housingLoan = Number(this.info.housingLoan)
      this.info.housingRent = Number(this.info.housingRent)
      this.info.support = Number(this.info.support)
      this.info.babyReduction = Number(this.info.babyReduction)



      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          saveEmployees([this.info]).then((e) => {
            if (e.data.msg != "success") {
              this.$message.error(e.data.msg);
              return
            }
            if(e.data.msg == 'success'){
              if (this.type1 === "编辑") {
                this.$notify({
                  title: "成功",
                  message: "修改成功",
                  type: "success",
                  duration: 2000
                });
                this.dialogFormVisible = false
                this.$emit("success")
              }else{
                this.$notify({
                  title: "成功",
                  message: "添加人员成功",
                  type: "success",
                  duration: 2000
                });
                this.dialogFormVisible = false
                this.$emit("success")
              }
              
            }
          })
        }
      })
    },
    resetList() {
      this.info = {
        iDType: "居民身份证",
        country: "中国",
        iDNo: '',
        birthday: '',
        sex: '',
        disability: 2,
        isEmployee: 1,
        stockholder: 2,
        martyred: 2,
        elderly: 2,
        totalStock:0,
        personalStock:0,
        stockPercent:0,
        joinDate:'',
        departureDate:''
      };
    },
    openDialog(row){
      this.resetList()
      if(row){
        this.info = row
      }
      // this.info = row
      this.dialogFormVisible = true
    },
  }
}
</script>

<style lang="scss" scoped>
.item_one {
  :deep(.el-input__wrapper ) {
    width: 200px;
  }
}
.item_one {
  :deep(.el-date-editor ) {
    width: 200px;
  }
}
.bottom_btns {
  width: 100%;
  margin-top: 30px;
  text-align: center;
}
.important-span {
  color: red;
}
.input-box {
  width: 100%;
  margin: 0 auto;
  margin-top: 20px;
  .item-input {
    float: left;
    width: 50%;
    padding: 0 15px;
    margin-top: 15px;
    p {
      float: left;
      width: 36%;
      margin-right: 10px;
      text-align: right;
      line-height: 28px;
      font-size: 14px;
      color: #333;
    }
  }
}
.item-input_big {
  float: left;
  width: 49%;
  padding: 0 15px;
  margin-top: 15px;
  p {
    float: left;
    width: 100px;
    margin-right: 10px;
    text-align: right;
    line-height: 28px;
    font-size: 14px;
    color: #333;
  }
  .el-input--mini{
    margin-right: 6px;
  }
}
.item_list {
  width: 100%;
  margin: 0 auto;
  padding: 20px;
  .title-box {
    width: 100%;
    margin: 0 auto;
    border-bottom: 2px solid #3ac2bc;
    line-height: 32px;
    font-size: 16px;
    color: #3ac2bc;
  }
}
.item-input {
  text-align: left;
}
.el-select {
  margin: 0px;
}
</style>