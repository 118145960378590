import request from '@/utils/request'

// 员工导出
export function exportSalary(data) {
  return request({
    url: 'api/v2/export/salary',
    method: 'post',
    data
  })
}

// 进项发票导出
export function exportReceiptOfReceipts(data) {
  return request({
    url: 'api/v2/export/exportReceiptOfReceipts',
    method: 'post',
    data
  })
}


// 销项发票导出
export function exportInvoice(data) {
  return request({
    url: 'api/v2/export/exportInvoice',
    method: 'post',
    data
  })
}

// 库存汇总表导出
export function inventorySummarySheet(data) {
  return request({
    url: 'api/v2/export/inventorySummarySheet',
    method: 'post',
    data
  })
}

// 出入库明细导出
export function inOutInventorySummarySheet(data) {
  return request({
    url: 'api/v2/export/inOutInventorySummarySheet',
    method: 'post',
    data
  })
}

// 资产导出
export function exportFixedAssets(data) {
  return request({
    url: 'api/v2/export/exportFixedAssets',
    method: 'post',
    data
  })
}
