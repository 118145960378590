<template>
  <!-- <el-tabs tab-position="left" style="height: 100%;" type="border-card" v-model="activeName" @tab-click="handleClick">
    <el-tab-pane label="员工" name="first">
      <remunerationEmployee ref="remunerationEmployee"></remunerationEmployee>
    </el-tab-pane>
    <el-tab-pane label="工资表" name="second">
      <remunerationSalary ref="remunerationSalary"></remunerationSalary>
    </el-tab-pane>
    <el-tab-pane label="累计数据" name="third">
      <information ref="information"></information>
    </el-tab-pane>
  </el-tabs> -->

  <qzf-tabs v-model:value="value" @tab-click="handleClick">
    <qzf-tabs-item label="员工" name="1" :cache="false">
      <remunerationEmployee ref="remunerationEmployee"></remunerationEmployee>
    </qzf-tabs-item>
    <qzf-tabs-item label="工资表" name="2" :cache="false">
      <remunerationSalary ref="remunerationSalary"></remunerationSalary>
    </qzf-tabs-item>
    <qzf-tabs-item label="累计数据" name="3" :cache="false">
      <information ref="information"></information>
    </qzf-tabs-item>
  </qzf-tabs>
</template>

<script>
import remunerationEmployee from './components/employee.vue'
import remunerationSalary from './components/salary.vue'
import information from './components/information.vue'
export default {
  name: "remuneration",
  components:{ remunerationEmployee,remunerationSalary,information },
  data () {
    return {
      value:'1',
    }
  },
  deactivated(){
    if(!this.$getCachedViews("remuneration")){//销毁 mitt bus
      this.$bus.off("salarySheetUpdate")
    }
  },
  created(){
    this.initBus()
  },
  methods:{
    initBus(){
      this.$bus.on("salarySheetUpdate", (val) => {
        if(this.$refs.remunerationSalary){
          this.$refs.remunerationSalary.getList()
        }
      });
    }
  }
}
</script>

<style>

</style>